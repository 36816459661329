@tailwind base;
@tailwind components;
@tailwind utilities;

html,
#root {
  @apply h-full;
}

body {
  @apply h-full bg-gray-800;
}

:root {
  color-scheme: dark;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: #475569;
}

::-webkit-scrollbar-thumb {
  background-color: #64748b;
  background-clip: padding-box;
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0284c7;
}

::-webkit-scrollbar-thumb:active {
  background-color: #0284c7;
}

/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: #475569;

  display: block;
  background-size: 8px;
  background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 10px;
  width: 8px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(148, 163, 184)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 8px;
  width: 8px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(148, 163, 184)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(148, 163, 184)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(148, 163, 184)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(203, 213, 225)'><polygon points='0,0 0,100 50,50'/></svg>");
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
